<template>
  <svg>
    <defs>
      <circle id="prefix__a" cx="24" cy="24" r="24" />
      <circle id="prefix__b" cx="24" cy="24" r="25" />
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(4 4)">
      <circle cx="24" cy="24" r="25" :stroke="color" stroke-width="2" />
      <circle cx="24" cy="24" r="26.5" :stroke="color" stroke-opacity=".3" stroke-width="3" />
      <g>
        <path
          :stroke="color"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M0 0L2.857 0 7.251 11.579 17.143 11.579 20 4.342 11.429 4.342"
          transform="translate(13 17)"
        />
        <path
          fill="#FFF"
          d="M11.579 17.895c0 1.162-.943 2.105-2.105 2.105s-2.106-.943-2.106-2.105.944-2.106 2.106-2.106 2.105.944 2.105 2.106M17.895 17.895c0 1.162-.943 2.105-2.106 2.105-1.162 0-2.105-.943-2.105-2.105s.943-2.106 2.105-2.106c1.163 0 2.106.944 2.106 2.106"
          transform="translate(13 17)"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
